import React from "react";
// import React, { useState } from "react";
// import emailjs from 'emailjs-com';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import CustomInput from "components/CustomInput/CustomInput.js";
// import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/workStyle.js";

const useStyles = makeStyles(styles);

export default function WorkSection() {

  const classes = useStyles();

  // https://stackoverflow.com/questions/60627287/issue-with-emailjs-react-hooks-preventdefault
  // const [values, setValues] = useState({
  //   name: '',
  //   email: '',
  //   message: '',
  // });

  // const handleChange = (name)=> (e) => {
  //   setValues({ ...values, [e.target.id]: e.target.value });
  // };

  // const isFormValid = () => {
  //   if (!values.name || !values.email || !values.message) {
  //     return false;}
  //   else {
  //     return true;}
  // };

  // const handleSubmit = (e) => {
  //   e.preventDefault()
  //   if (!isFormValid()) {
  //     console.log('form is invalid')
  //   } else{ 
  //     sendEmail(e)
  //   }
  //   // sendEmail(e)
  // };

  // const sendEmail = (e) => {
  //   emailjs.sendForm('service_qdasfzo', 'template_1acewng', e.target, 'user_B53nQuh0ma4yOMQ1biayw')
  //     .then((result) => {
  //       setValues({name: '', email: '', message: ''})
  //     }, (error) => {

  //     });
  // }

  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem cs={12} sm={12} md={8}>
          <h2 className={classes.title}>Work with us</h2>
          <h4 className={classes.description}>
            Reach out to us to learn more
          </h4>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <h4 className={classes.infoCardHeader}>Email</h4>
                <a className={classes.infoCardContent} href="mailto:info@lexifyhealth.com"><h4>info@lexifyhealth.com</h4></a>
              </GridItem>
              
              {/* <GridItem xs={12} sm={12} md={6}>
                <h4 className={classes.infoCardHeader}>Phone</h4>
                <a className={classes.infoCardContent} href="tel:888-988-5538"><h4>888-988-5538</h4></a>
              </GridItem> */}

            </GridContainer>

          {/* <form id="contact-form" onSubmit={(e) => handleSubmit(e)}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Your Name"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{ 
                    required: true,
                    onChange: handleChange(),
                    id: "name",
                    name: "name",
                    value: values.name,
                    type: "text"
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Your Email"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    required: true,
                    onChange: handleChange(),
                    id: "email",
                    name: "email",
                    value: values.email,
                    type: "email"
                  }}
                />
              </GridItem>
                <CustomInput
                  labelText="Your Message"
                  formControlProps={{
                    fullWidth: true,
                    className: classes.textArea
                  }}
                  inputProps={{
                    multiline: true,
                    rows: 5,
                    required: true,
                    onChange: handleChange(),
                    id: "message",
                    name: "message",
                    value: values.message,
                    type: "text",
                    autoComplete: "off"
                  }}
                />
              <GridItem xs={12} sm={12} md={4}>
                <Button color="primary" type="submit" value="Submit" >Send Message</Button>
              </GridItem>
            </GridContainer>
          </form> */}
        </GridItem>
      </GridContainer>
    </div>
  );
}
